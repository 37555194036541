/*********************************************
:: Contact Page                   
********************************************/
.page-contact {
    .title-module{
        @include mappy-bp(mediumish){
            margin-bottom: 85px;
        }
    }

    .contact-container {
        display: inline-block;
        width: 100%;

        h3 {
            padding: 0 0 6px 0;
            width: 100%;

            font-family: $font-family-1;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: black;

            border-bottom: 1px solid #CCC;
        }

        .intro {
            display: none;
            @include mappy-bp(mediumish) {
                display: block;
                margin-bottom: 58px;
                color: $color-grey-text;
                font-size: 16px;
                font-weight: 100;
                line-height: 33px;
            }
        }

        .col1 {
            float: left;
            margin-bottom: 35px;
            width: 100%;

            @include mappy-bp(mediumish) {
                width: 50%;
                max-width: 420px;
            }

            h3 {
                max-width: 323px;
                padding: 30px 0 4px 0;
            }

            p {
                margin: 0 0 4px 0;
                font-size: 14px;
                line-height: 24px;

                @include mappy-bp(mediumish) {
                    margin: 0px 0 8px 0;
                }
            }

            a {
                display: block;
            }
            .select-group{
                p{
                    font-size: 13px;
                    font-weight: 100;
                    line-height: 30px;
                    margin-bottom: 0;
                }
                select{
                    visibility: hidden;
                }
                .selectric-wrapper{
                    margin-bottom: 25px;
                    @include mappy-bp(mediumish){
                        margin-bottom: 45px;
                    }
                }
                .selectric {
                    border-radius: 4px;

                    .label{
                        font-weight: 100;
                    }
                    .button{
                        border: none;
                    }
                }
                .selectric-items li{
                    font-weight: 300;
                }
            }

            .phone {
                margin: 0;
                font-size: 21px;
                font-weight: 600;
                //font-family: Arial;
                line-height: 29px;
                color: $color-brand-1;
                clear: both;
                @include mappy-bp(mediumish){
                    margin-bottom: 5px;
                }
            }

            .email {
                font-size: 16px;
                color: #333;
                line-height: 20px;
            }
            .address{
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
            }

        }

        .col2 {
            float: left;
            padding: 20px 0% 0 0%;
            width: 100%;

            @include mappy-bp(mediumish) {
                width: 50%;
                float: right;
                padding: 0px;
            }
        }

        .map-area {
            display: inline-block;
            margin: 30px 0;
            width: 100%;

            .map {
                float: left;
                margin: auto;
                width: 100%;
                height: 300px;
            }
        }
    }//CONTACT-CONTAINER
    
}