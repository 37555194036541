/*********************************************
:: Accordion Module                    
********************************************/
.accordion-module{
	width: 100%;
	display: inline-block;
    position: relative;
    border-bottom: 1px solid #979797;
	@include mappy-bp(mediumish){
        margin-bottom: 61px;

        &:after {
            height: 220px;
        }
	}

    article{
        border-top: 1px solid #979797;
        padding: 20px 0 18px;
        @include mappy-bp(mediumish){
            padding: 26px 0 19px;
        }
        header{
            position: relative;

            font-size: 18px;
            font-weight: 300;
            color: $color-brand-1;

            z-index: 9;
            text-transform: initial;
            @include mappy-bp(mediumish){
                font-size: 18px;
                line-height: 29px;
            }
            span{
                opacity: 0.54;
                float: right;
                font-size: 29px;
                font-weight: 300;
                @include mappy-bp(mediumish){

                }
            }
        }
        .content{
            display: none;
            margin-top: 25px;
            margin-bottom: -20px;
            @include mappy-bp(mediumish){
                margin-bottom: -25px;
            }
            p{
                color: $color-brand-2;
                font-size: 15px;
                font-weight: 300;
                line-height: 30px;
            }
        }
    }

}
/********************************************/