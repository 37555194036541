/*********************************************
:: FAQ Page
********************************************/
.page-faq {

    .content-max-width{
        max-width: 950px;
    }
    .content-container {
        display: inline-block;
        width: 100%;

        .intro {
            display: none;
            @include mappy-bp(mediumish) {
                display: block;
                margin-bottom: 65px;
                color: $color-grey-text;
                font-size: 16px;
                font-weight: 300;
                line-height: 33px;
            }
        }

        .content-container {
            margin-bottom: 35px;
            width: 100%;

            @include mappy-bp(mediumish) {
                max-width: 420px;
            }
        }
        header.title{
            margin-bottom: 30px;
            @include mappy-bp(mediumish){
                margin-bottom: 55px;
            }
        }
    }//CONTENT-CONTAINER
    
}