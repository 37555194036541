/*********************************************
:: Blue Text Container Module
********************************************/
.blue-text-module{
    height: auto;

    text-align: center;
    border: 1px solid $color-grey;
    background-image: linear-gradient(201deg, #74af99 0%, #008ea7 100%);
    @include mappy-bp(mediumish){
        padding: 36px 62px;
    }
    p{
        margin-bottom: 0;
        font-size: 18px;
        color: white;

        line-height: 25px;
        font-weight: 100;
        &:last-of-type{
            font-weight: 500;
            margin-top: 20px;
        }
        @include mappy-bp(mediumish){
            font-size: 36px;
            line-height: 54px;
            &:last-of-type{
                margin-top: 0;
            }
        }
    }
}
/********************************************/