/*********************************************
:: Home Page                   
********************************************/
.page-home {

    .hero-container {
        position: relative;
    	width: 100%;
        @include mappy-bp(mediumish){
            margin-top: -4px;
        }

        .slide {
            position: relative;
            width: 100%;
            height: 145px;
            @include bg(cover);

            @include mappy-bp(smallish){
                height: 345px;
            }
            @include mappy-bp(mediumish){
                height: 557px;
            }
        }

    	.slide-text {
    		position: absolute;
            top: 35%;
            left: 0;
            right: 0;
            //border-bottom: 7px solid $color-brand-2;
            text-align: center;

    		@include mappy-bp(mediumish){
    			top: 200px;
    		}

    		h1, h2 {
    			font-size: 18px;
                font-weight: 300;
                line-height: 23px;
                color: white;
                font-family: $font-family-1;
                margin-bottom: 0;

    			@include mappy-bp(mediumish){
                    font-size: 48px;
                    line-height: 59px;
                }
            }
            h1:last-of-type{
                font-weight: 700;
            }

            h2{
                display: inline-block;
                width: 39px;

                position: relative;

                font-style: italic;
                font-family: $font-family-3 !important;
                font-weight: 100;
                &:before, &:after{
                    width: 21px;
                    height: 1px;

                    position: absolute;
                    top: 11px;

                    background-color: #ffffff;
                    content: '';
                }
                &:before{
                    left: -16px;
                }
                &:after{
                    right: -16px;
                }
    			@include mappy-bp(mediumish){
                    width: 138px;
                    &:before, &:after{
                        width: 42px;
                        top: 33px;
                    }
                    &:before{
                        left: -16px;
                    }
                    &:after{
                        right: -8px;
                    }
                }
            }
    	}
    }//HERO-CONTAINER

    .content-intro{
        margin-top: -12px;
        z-index: 9;
        position: relative;
        @include mappy-bp(mediumish){
            margin-top: -30px;
        }

        header{
            //height: 47px;
            font-size: 14px;
            line-height: 19px;
            padding: 10px 20px 10px;

            margin: auto;
            max-width: 957px;
            width: 100%;
            background-image: linear-gradient(135deg, #75ae99 0%, #008ea8 100%);

            color: #ffffff;
            font-family: $font-family-1;
            font-weight: 100;

            text-align: center;
            @include mappy-bp(mediumish){
                //height: 68px;
                font-size: 36px;
                line-height: 55px;
                padding: 23px 37px 28px;
            }
        }
        article{
            width: 100%;
            margin: 20px auto 18px;
            position: relative;

            font-family: $font-family-1;
            font-size: 13px;
            font-weight: 300;
            color: $color-grey-text;
            
            text-align: center;
            line-height: 20px;
            @include mappy-bp(mediumish){
                width: 65%;
                margin: 76px auto 82px;
                padding-bottom: 66px;

                font-size: 16px;
                line-height: 33px;

                &::after{
                    height: 1px;
                    width: 90%;
                    max-width: 598px;

                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;

                    background-color: $color-grey;
                    content: '';
                }
            }
        }
    }

    .blue-text-module{
        transition: all .2s ease-in-out;
        &:hover{
            opacity: 0.9;
        }
    }
    .video{
        margin: 23px auto 10px;
        @include mappy-bp(mediumish){
            margin: 111px auto 122px;
        }
        iframe{
            display: inline-block;
            width: 100%;
            min-height: 224px;
            @include mappy-bp(mediumish){
                width: 560px;
                height: 357px;
            }
        }
        article{
            display: inline-block;
            vertical-align: top;
            text-align: center;
            @include mappy-bp(mediumish){
                text-align: left;
                max-width: 50%;
                padding: 49px 23px 18px 37px;
            }
            header{
                color: #a3c07a;
                // font-family: "Museo Sans";
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 31px;
                @include mappy-bp(max-width mediumish){
                    margin-bottom: 10px;
                    margin-top: 21px;
                    font-size: 18px;
                }
            }
            p{
                font-size: 15px;
                font-weight: 300;
                line-height: 30px;
                @include mappy-bp(mediumish){
                    padding-left: 5px
                }
            }
        }
    }
    .social{
        background-repeat: no-repeat;
        font-size: 0;
        background-size: cover;
        @include mappy-bp(max-width mediumish){
            background-image: none!important;
        }
        .content-max-width{
            display: flex;
        }
        .testimonials, #tweetsContainer{
            @include mappy-bp(mediumish){
                padding-top: 41px;
            }

            header{
                margin-bottom: 51px;
                font-size: 18px;
                font-style: italic;
                color: white;
                text-transform: uppercase;
                font-weight: 300;
            }
        }
        #tweetsContainer {
            @include mappy-bp(mediumish){
                width: 36.1%;
                display: inline-block;
                vertical-align: top;
                padding-right: 6.2%;
                border-right: 2px solid white;
                padding-bottom: 59px;
            }
            header{
                @include mappy-bp(max-width mediumish){
                    padding-top: 21px;
                    margin-bottom: 14px;
                    color: $color-brand-1;
                }
            }
            article {
                margin: 0 0 10px;
                padding: 13px 28px;
                //box-shadow: 0 2px 4px rgba(155, 155, 155, 0.66);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.31);
                background-color: #ffffff;

                p {
                    font-size: 12px;
                    font-weight: $font-normal;
                    font-style: italic;
                    line-height: 20px;
                }

                p.top {
                    font-style: normal;
                    color: $color-twitter;
                    font-family: "Helvetica Neue";

                    span.time {
                        float: right;
                        color: #39393a;
                    }
                }
            }
        }
        .testimonials{
            @include mappy-bp(max-width mediumish){
                padding: 16px 7% 20px;
                background-size: cover;
                background-repeat: no-repeat;
            }
            @include mappy-bp(mediumish){
                width: 63.9%;
                display: inline-block;
                padding-left: 45px;
                background-image: none!important;
                padding-bottom: 59px;
            }
            header{
                margin-bottom: 14px;
                @include mappy-bp(mediumish){
                    margin-left: 71px;
                }
            }
            article{
                @include mappy-bp(max-width mediumish){
                    padding: 0 33px;
                }
                @include mappy-bp(mediumish){
                    clear: both;
                    padding-top: 12px;
                    min-height: 171px;
                }
            }
            .text{
                vertical-align: bottom;
                position: relative;
                @include mappy-bp(mediumish) {
                    width: 68%;
                    display: inline-block;
                    padding-left: 47px;
                }
                &:before, &:after{
                    width: 24px;
                    height: 31px;
                    position: absolute;
                    content: '';
                    background-image: url("/images/icons/quote.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    @include mappy-bp(mediumish){
                        width: 40px;
                    }
                }
                &:before{
                    @include mappy-bp(max-width mediumish){
                        left: -37px;
                    }
                    left: 0;
                }
                &:after{
                    right: -21px;
                    bottom: 37px;
                    transform: rotate(180deg);
                }
                p{
                    color: white;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 23px;
                    &.quote{
                        @include mappy-bp(mediumish){
                            margin-bottom: 10px;
                            padding-top: 15px;
                        }
                    }
                }
            }
            .name{
                @include mappy-bp(max-width mediumish){
                    float: right;
                    width: 60%;
                    text-align: left;
                    margin-top: 15px;
                }
                text-align: right;
                span{
                    font-weight: 100;
                    @include mappy-bp(max-width mediumish){
                        display: block;
                    }
                }
            }

            img{
                width: 82px;
                height: 82px;

                border: 3px solid white;
                border-radius: 50%;
                background-color: white;

                @include mappy-bp(mediumish){
                    width: 171px;
                    height: 171px;
                    float: right;
                    margin-top: -21px;
                    margin-right: 6px;
                }
            }
            article{
                &:first-of-type{
                    img{
                        @include mappy-bp(mediumish){
                            margin-top: -31px;
                        }
                    }
                }

                &:nth-of-type(even){
                    @include mappy-bp(mediumish){
                        padding-top: 22px;
                        padding-bottom: 20px;
                    }
                    img{
                        @include mappy-bp(mediumish){
                            float: left;
                        }
                    }
                    .text {
                        @include mappy-bp(mediumish){
                            margin-left: 23px;
                        }
                       &:after {
                           right: -36px;
                       }
                    }
                }
            }
        }
    }
}
