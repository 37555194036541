/*********************************************
:: Title Module                    
********************************************/
.title-module{
	width: 100%;
    height: 90px;
	display: inline-block;
    position: relative;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    &:after {
        display: block;
        height: 90px;
        width: 100%;

        position: absolute;
        top: 0;

        content: '';
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.71) 0%, rgba(0, 0, 0, 0.1) 100%);
    }
	@include mappy-bp(mediumish){
        height: 220px;
		padding-bottom: 30px;
        margin-bottom: 92px;

        &:after {
            height: 220px;
        }
	}

    h1 {
        //padding: 40px 0 0;
        position: relative;

        font-size: 18px;
        font-weight: 300;
        color: #ffffff;

        text-transform: uppercase;
        line-height: 90px;
        z-index: 9;
        @include mappy-bp(mediumish){
            font-size: 24px;
            line-height: 220px;
            text-transform: uppercase;
        }
	}

}
/********************************************/