/*********************************************
  :: Site Header
********************************************/
.site-header {
	position: relative;
	width: 100%;
	height: $header-height-mobile;
	z-index: 99;

    .content-max-width{
        max-width:1152px;
    }
	@include mappy-bp(mediumish){
		height: $header-height-desktop;
	}

	.logo {
		position: absolute;
        top: 50px;
        left: 4.5%;
        transition: none;
        z-index: 9;

		@include mappy-bp(mediumish){
		    top: 23px;
			left: -1px;
			//width: 324px;
		}

		img {
			height: 45px;
			@include mappy-bp(mediumish){
                height: 105px;
			}
		}
	}

	nav {
		position: absolute;
		top: 7px;
		right: 0;
        padding-left: 2%;
        width: 100%;

        text-align: left;
        border-bottom: 1px solid #dddddd;
        @include mappy-bp(mediumish){
            padding-left: 0;
		    top: 14px;
		    text-align: right;
            border-bottom: none;
        }

		li { 
			display: inline-block;
            margin-bottom: 1px;
			position: relative;
            @include mappy-bp(mediumish){

            }
		}

		a {
			display: inline-block;
			margin-left: 10px;
            font-size: 18px;
            font-weight: 500;
            color: #4a4a4a;
            text-transform: uppercase;
            @include mappy-bp(mediumish){
                margin-left: 7px;
            }
			
			&:hover {
				opacity: 0.7;
			}

			&.selected {
				color: $color-brand-2; 
				&:hover { opacity: 1; }
				&:active { opacity: 1; }
			}
		}
        .social-icons{
            .small-top{
                top: 1px;
            }
            .tel-margin{
                top: 3px;
            }
            a{
                &.phone-number{
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 1.54px;
                }

                .fa{
                    color: $color-brand-1;
                }
            }
        }
        .bottom{
            margin-top: 51px;
            @include mappy-bp(max-width mediumish){
                display: none;
            }
            li{
                &:nth-of-type(odd){
                    margin-right: 93px;
                }
                &:nth-of-type(even){
                    margin-right: 92px;
                }
                &:last-of-type{
                    margin-right: 0;
                }
                a{
                    margin-left: 0;
                }
            }
        }
	}
    .button-transparent{
        margin-top: 5px;
        margin-bottom: 15px;
        padding-top: 1px;

        font-size: 13px;
        font-weight: 500;
        color: $color-brand-1;

        line-height: 23px;

        &.mobile{
            position: absolute;
            top: 69px;
            right: 17%;
            font-size: 12px;
            line-height: 22px;
        }
    }
    .tel{
        margin: 0 26px 0 40px;

        font-size: 24px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 1.54px;
    }

    .tel, tel-mob{
        color: $color-brand-2;
        font-weight: $font-bold;
    }
	.tel-mob {
		display: inline-block;
		position: absolute;
		top: 5px;
		right: 3%;

        font-size: 16px;
        font-style: italic;
		line-height: 14px;
		letter-spacing: 2px;

		@include mappy-bp(mediumish){
			display: none;
		}
	}

	.hamburger {
        position: absolute;
        top: 75px;
		right: 3%;

		width: 26px;
    	height: 17px;

		span {
			font-size: 17px;
			color: black;
		}

        &:focus {
            outline: none;
        }
    }
}

/********************************************/