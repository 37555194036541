/*********************************************
:: Special Projects Page
********************************************/
.page-special-projects {

    .title-module{
        @include mappy-bp(mediumish){
            margin-bottom: 82px;
        }
    }

    .content-container {
        display: inline-block;
        width: 100%;
        @include mappy-bp(mediumish){
            margin-bottom: 40px;
        }

        section.content-max-width{
            article.wysiwyg{
                border-top: 1px solid $color-brand-1;
                padding: 42px 0 22px;
                &:first-of-type{
                    border-top: none;
                    padding-top: 0;
                    @include mappy-bp(mediumish){
                        padding: 0 0 69px;
                    }
                }
                @include mappy-bp(mediumish){
                    padding: 42px 0 42px;
                    max-width: 96%;
                    margin: auto;
                }
                section{
                    display: inline-block;
                    vertical-align: middle;
                    @include mappy-bp(max-width mediumish){
                        margin-bottom: 20px;
                    }
                    p{
                        @include mappy-bp(max-width mediumish){
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                        }
                    }
                    @include mappy-bp(mediumish){
                        img{
                            max-width: 287px;
                            max-height: 254px;
                        }
                        p{
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 24px;
                            margin-bottom: 15px;
                        }
                        &:first-of-type{
                            margin-right: 129px;
                            border-top: none
                        }
                        &:nth-of-type(2){
                            max-width: 28%;
                            padding-right: 46px;
                            padding-left: 1px;
                            margin-right: 53px;
                        }
                        &:last-of-type{
                            max-width: 23%;
                        }
                    }
                }
                &.full-width{
                    width: 100%;
                    @include mappy-bp(mediumish){
                        max-width: 100%;
                    }
                    p{
                        font-size: 16px;
                        line-height: 33px;
                    }
                }
            }
        }

        .content-container {
            margin-bottom: 35px;
            width: 100%;

            @include mappy-bp(mediumish) {
                max-width: 420px;
            }
        }

        header.title{
            line-height: 29px;
            margin-bottom: 20px;
            @include mappy-bp(mediumish){
                font-size: 18px;
                //font-weight: 700;
                margin-bottom: 11px;
            }
        }
    }//CONTENT-CONTAINER
    #non-house-form{
        background-color: #d8d8d8;
        @include mappy-bp(mediumish){
            height: 382px;
        }
        h2{
            margin-bottom: 24px;
            padding-top: 40px;
            color: $color-brand-1-1;
            font-weight: 400;
            font-size: 23px;
            @include mappy-bp(mediumish){
                padding-top: 50px;
                font-size: 24.2px;
            }
        }
        hr{
            //height: 3px;
            margin-bottom: 28px;
            background-color: #979797;
            @include mappy-bp(mediumish){
                margin-bottom: 18px;
            }
        }
        .left{
            display: inline-block;
            vertical-align: top;
            @include mappy-bp(mediumish){
                text-align: right;
                max-width: 43.6%;
            }

        }
        .right{
            display: inline-block;
            width: 100%;
            @include mappy-bp(mediumish){
                text-align: right;
                max-width: 41.1%;
            }
            label{
                text-align: left;
                @include mappy-bp(mediumish){
                    position: absolute;
                    top: 12px;
                    left: 95px;
                    width: auto;
                }
            }
        }
        label{
            margin-right: 17px;
        }
        .form-field{
            position: relative;
            padding: 0 0 4px;
            label{
                margin-right: 15px;
                font-size: 14px;
                font-weight: 500;
                color: #4a4a4a;
                letter-spacing: 0.14px;

            }
        }
        input{
            width: 100%;
            max-width: 299px;
            height: 30px;
            border-radius: 4px;
            border: 1px solid #bbbbbb;
            @include mappy-bp(max-width mediumish){
                margin-top: 2px;
            }
            @include placeholder{
                color: #c6c6c6;
                font-size: 14px;
                font-weight: 300;
                line-height: 30px;
            }
        }
        textarea{
            width: 100%;
            max-width: 299px;
            height: 132px;
            border-radius: 4px;
            border: 1px solid #bbbbbb;
        }
        .button-field{
            button{
                width: 113px;
                height: 36px;
                font-size: 15px;
                font-weight: 400;
                margin-top: 15px;
                @include mappy-bp(mediumish){
                    margin-top: 20px;
                }

            }
        }
    }
}
