/*********************************************
:: Who are we Page
********************************************/
.page-who-are-we{

    .title-module{
        @include mappy-bp(mediumish){
            margin-bottom: 86px;
        }
    }

    .content-container {
        display: inline-block;
        width: 100%;
        @include mappy-bp(mediumish){
            margin-bottom: 40px;
        }

        aside{
            max-width: 1150px;
            margin: auto;
            @include mappy-bp(mediumish){
                float: right;
                width: 47.8%;
            }

            img{
                float: right;
                margin-bottom: 23px;
            }

            figure{
                figcaption{
                    font-size: 12px;
                    font-weight: 300;
                    color: $color-brand-2;
                    line-height: 17px;
                }
            }
        }

        section{
            article{
                @include mappy-bp(mediumish){
                    max-width: 43.9%;
                }
            }
        }

        .content-container {
            margin-bottom: 35px;
            width: 100%;

            @include mappy-bp(mediumish) {
                max-width: 420px;
            }
        }

        header.title{
            margin-bottom: 30px;
            @include mappy-bp(mediumish){
                margin-bottom: 34px;
                font-size: 24px;
                line-height: 28px;
            }
        }
    }//CONTENT-CONTAINER
}