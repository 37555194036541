/*********************************************
:: Plans Page
********************************************/
.page-plans {
    padding-bottom: 30px;
    @include mappy-bp(mediumish){
        padding-bottom: 70px;
    }

    .title-module{
        @include mappy-bp(mediumish){
            margin-bottom: 84px;
        }
    }

    .hero-container {
        position: relative;
    	width: 100%;
        @include mappy-bp(mediumish){
            margin-top: -4px;
        }

        .slide {
            position: relative;
            width: 100%;
            height: 145px;
            @include bg(cover);

            @include mappy-bp(smallish){
                height: 345px;
            }
            @include mappy-bp(mediumish){
                height: 557px;
            }
        }

    	.slide-text {
    		position: absolute;
            top: 35%;
            left: 0;
            right: 0;
            border-bottom: 7px solid $color-brand-2;
            text-align-last: center;

    		@include mappy-bp(mediumish){
    			top: 200px;
    		}

    		h1, h2 {
    			font-size: 18px;
                font-weight: 300;
                line-height: 23px;
                color: white;
                font-family: $font-family-1;
                margin-bottom: 0;

    			@include mappy-bp(mediumish){
                    font-size: 48px;
                    line-height: 59px;
                }
            }
            h1:last-of-type{
                font-weight: 700;
            }

            h2{
                display: inline-block;
                width: 39px;

                position: relative;

                font-style: italic;
                font-family: $font-family-3 !important;
                font-weight: 100;
                &:before, &:after{
                    width: 21px;
                    height: 1px;

                    position: absolute;
                    top: 11px;

                    background-color: #ffffff;
                    content: '';
                }
                &:before{
                    left: -16px;
                }
                &:after{
                    right: -16px;
                }
    			@include mappy-bp(mediumish){
                    width: 138px;
                    &:before, &:after{
                        width: 42px;
                        top: 33px;
                    }
                    &:before{
                        left: -16px;
                    }
                    &:after{
                        right: -8px;
                    }
                }
            }
    	}
    }//HERO-CONTAINER

    .content-container {
        display: inline-block;
        width: 100%;
        @include mappy-bp(mediumish) {
            margin-bottom: 24px;
        }

        section {
            article {
                @include mappy-bp(mediumish) {
                    //max-width: 43.9%;
                }
                &.wysiwyg {
                    p {
                        font-size: 16px;
                        line-height: 33px;
                    }
                    figure {
                        @include mappy-bp(mediumish) {
                            margin: 56px auto 40px;
                        }
                    }
                }
            }
        }
    }
    .boxes-module{
        @include mappy-bp(mediumish){
            margin-bottom: 53px;
        }
    }
    .blue-text-module{
        @include mappy-bp(mediumish){
            margin-bottom: 109px;
        }
    }
    .content-max-width.full-width{
        @include mappy-bp(max-width mediumish){
            padding: 0;
        }

    }
    .plan-panels{
        @include mappy-bp(max-width mediumish){
            margin-top: 40px;
        }

    }
    #panel2, #panel3{
        display: none;
    }
    section{
        header.form-title{
            text-align: center;
            margin-bottom: 20px;
            position: relative;
            @include mappy-bp(mediumish){
                margin-top: 48px;
                margin-bottom: 48px;
            }
            p:first-of-type{
                color: #a3c07a;
                font-family: $font-family-1;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 40px;
            }
            p:last-of-type{
                display: inline-block;
                width: 44px;
                height: 44px;
                margin: auto;
                background-color: $color-brand-1;
                border-radius: 50%;
                span{
                    display: block;
                    width: 40px;
                    height: 40px;
                    margin: 2px  auto 0;

                    font-size: 15px;
                    font-weight: 700;
                    color: white;
                    border-radius: 50%;
                    border: 1px solid #ffffff;
                    line-height: 40px;
                    &:before, &:after{
                        content: '';
                        width: 47.8%;
                        height: 1px;
                        position: absolute;
                        bottom: 21px;
                        background-color: $color-brand-1;
                        z-index: -1;
                    }
                    &:before{
                        left: 0;
                    }
                    &:after{
                        right: 0;
                    }
                }
            }
        }
    }
    .plans-list {
        position: relative;
        ul {
            visibility:  hidden;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -999;

            li {
                position: relative;
                height: 94px;
                lost-column: 1/4 4 3px;
                display: inline-block;
                margin-bottom: 0;

                color: $color-grey-labels;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                vertical-align: top;
                background-color: #e0e0e0;
                transition: all 0.25s ease-in-out;
                cursor: pointer;
                @include mappy-bp(mediumish) {
                    font-size: 24px;
                }
                &.selected {
                    background-color: $color-brand-4;
                    p {
                        color: white;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -17px;
                        border-bottom: 4px solid transparent;
                        border-top: 13px solid $color-brand-4;
                        border-right: 6px solid transparent;
                        border-left: 6px solid transparent;
                    }
                }
                p {
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 100%;
                    padding-top: 20px;
                    @include mappy-bp(mediumish) {
                        padding: 22px 0;
                    }

                }
                &:first-of-type, &:last-of-type {
                    line-height: 91px;
                    p {
                        height: 100%;
                        @include mappy-bp(mediumish) {
                            padding: 35px 0;
                        }
                    }
                }
            }
        }
        .intro {
            width: 73%;
            margin: auto;
            padding-top: 20px;
            font-size: 14px;
            font-weight: 500;
            color: $color-grey-labels;
            line-height: 19px;
            clear: both;
            text-align: center;
            margin-bottom: 40px;
            @include mappy-bp(mediumish) {
                // padding-top: 40px;
                padding-top: 0;
                margin-bottom: 55px;
            }
        }
    }
    .plans-options{
        padding: 30px 0 30px;
        display: none;
        @include mappy-bp(mediumish){
            padding: 30px 40px 30px;
            margin-bottom: 58px;
        }
        .plan-grid{
            transition: all 0.25s ease-in-out;
            display: none;

            &#rural-wireless-plans {
                .divTableBody .divTableRow div:nth-of-type(2) {
                    background-color: #e0e0e0;
                }
            }

            div.plan-grid {
                width: 100%;
                text-align: center;
            }
            .divTable.plan-grid .divTableCell, .divTable.plan-grid .divTableHead {
                padding: 3px 2px;
                //vertical-align: middle;
            }
            .divTable.plan-grid .divTableBody .divTableCell {
                color: #4A4A4A;
                font-size: 26px;
                font-weight: 500;
                //vertical-align: middle;
            }
            .divTableCell, .divTableHeading{
                vertical-align: middle;
                @include mappy-bp(mediumish){
                    min-width: 256px;
                }
            }

            .divTable.plan-grid .divTableHeading {
            }
            .divTable.plan-grid .divTableHeading .divTableHead {
                font-size: 15px;
                font-weight: normal;
                color: #FFFFFF;
            }
            .divTableHead{
                font-size: 16px;
                color: white;
                vertical-align: middle;
                @include mappy-bp(mediumish){
                    font-size: 26px;
                }
                span{
                    font-size: 20px;
                }
                @include mappy-bp(max-width mediumish){
                    width: 23%;
                    max-width: 224px;

                }
                &:first-of-type{
                    border: none;
                }
                &:nth-of-type(2){
                    background-color: #64b9c7;
                }
                &:nth-of-type(3){
                    background-color: #2fa2b8;
                }
                &:nth-of-type(4){
                    background-color: #008ea7;
                }
            }
            &#rural-zone-plans{
                .divTableHead{
                    &:first-of-type{
                        border: none;
                    }
                    &:nth-of-type(2), &:nth-of-type(3){
                        width: 37.6%;
                    }
                }
            }
            &#urban-ufb-plans{
                .divTableHead{
                    &:first-of-type{
                        border: none;
                    }
                    &:nth-of-type(2), &:nth-of-type(3){
                        width: 37.6%;
                    }
                }
            }
            &#urban-wireless-plans{
                .divTableHead{
                    &:nth-of-type(2){
                        width: 75%;
                    }

                }
            }
            .plan-grid .tableFootStyle .links {
                text-align: right;
            }
            .plan-grid .tableFootStyle .links a{
                display: inline-block;
                background: #1C6EA4;
                color: #FFFFFF;
                padding: 2px 8px;
                border-radius: 5px;
            }
            .plan-grid.outerTableFooter {
                border-top: none;
            }
            .plan-grid.outerTableFooter .tableFootStyle {
                padding: 3px 5px;
            }
            /* DivTable.com */
            .divTable{
                display: table;
            }
            .divTableRow {
                display: table-row;
                position: relative;
                &:first-of-type{
                    .divTableVerticalHeader{
                        background-color: #64b9c7;
                    }
                }
                &:nth-of-type(2){
                    .divTableVerticalHeader{
                        background-color: #5bb6c7;
                    }
                }
                &:nth-of-type(3){
                    .divTableVerticalHeader{
                        background-color: #2fa2b8;
                    }
                }
                &:nth-of-type(4){
                    .divTableVerticalHeader{
                        background-color: #008ea7;
                    }
                }
            }
            .divTableHeading {
                display: table-header-group;
            }

            .divTableCell, .divTableHead {
                height: 86px;
                display: table-cell;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                border: 2px solid white;
                @include mappy-bp(mediumish){
                    font-size: 26px;
                }
                span{
                    display: block;
                    font-size: 13px;
                    @include mappy-bp(mediumish){
                        font-size: 20px;
                    }
                }

            }
            .divTableVerticalHeader{
                color: white;
                font-size: 16px;
                height: 83px;
                width: 256px;
                text-align: center;
                vertical-align: middle;
                display: table-cell;
                border: 2px solid white;
                @include mappy-bp(mediumish){
                    font-size: 26px;
                }
                span{
                    font-size: 14px;
                    display: block;
                }
            }

            .divTableCell {
                cursor: pointer;
                transition: all 0.25s ease-in-out;
                position: relative;
                &.selected{
                    background-color: #a8c785 !important; /* !important is for ##rural-wireless-plans override */
                    color: white;
                }
                .note{
                    // position: absolute;
                    position: static;
                    padding: 6%;
                    // width: 215%;
                    width: auto;
                    // height: 82px;
                    height: auto;
                    margin: 0;
                    line-height: 17px;
                    background-color: #efefef;
                    top: 0;
                    font-size: 12px;
                    z-index: 9;
                    @include mappy-bp(mediumish){
                        // width: 506px;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
            .divTableHeading { display: table-header-group;}
            .divTableFoot { display: table-footer-group;}
            .divTableBody { display: table-row-group;}
        }
        footer{
            margin-top: 25px;
            padding: 0 5%;
            display: none;
            @include mappy-bp(mediumish){
                padding: 0;
            }
            ul{
                li{
                    position: relative;
                    padding-left: 5px;
                    margin-bottom: 0;

                    font-size: 12px;
                    font-weight: 300;
                    color: $color-grey-labels;
                    line-height: 20px;
                    &:before{
                        position: absolute;
                        height: 2px;
                        width: 2px;
                        content: '';
                        background-color: #4a4a4a;
                        border-radius: 50%;
                        left: 0;
                        top: 9px;
                    }
                }
            }
        }

    }
    .panel{
        box-shadow: 0 7px 16px rgba(0, 0, 0, 0.13);
        background-color: #efefef;
        @include mappy-bp(mediumish){
            max-width: 1104px;
            width: 96%;
            margin: auto;
        }

        &.add-ons{
            padding: 27px 9px 27px 21px;
            font-size: 0;
            margin-bottom: 35px;
            @include mappy-bp(medium){
                padding: 38px 9px 24px 7%;
            }
            @include mappy-bp(mediumish){
                padding: 27px 61px 27px 47px;
                margin-bottom: 49px;
            }
            .add-ons-section{
                display: inline-block;
                width: 93%;
                @include mappy-bp(mediumish) {
                    display: inline-block;
                    vertical-align: top;

                    &:first-of-type{
                        //width: 50.5%;
                        width: 49%;
                        border-right: 1px solid rgba(151, 151, 151, 0.51);
                    }
                    &:last-of-type{
                        //width: 49.5%;
                        width: 51%;
                        padding-left: 6%;
                        .toggle-group{
                            margin-left: 9%
                        }
                    }
                    article:first-of-type{
                        margin-bottom: 13px;
                    }
                }

                h4{
                    color: $color-grey-labels;
                    font-size: 20px;
                    font-weight: 500;
                    width: 60%;
                    display: inline-block;
                    @include mappy-bp(medium){
                        width: 84%;
                    }
                    @include mappy-bp(mediumish){
                        margin-bottom: 0;
                        width: 69%;
                    }
                    .option-note{
                        display: block;
                        font-size: 12px;
                        font-weight: 300;
                        color: inherit;
                        line-height: 18px;
                    }
                }
                .toggle-group{
                    float: right;
                    @include mappy-bp(medium){
                        float: none;
                    }
                }
            }
        }

        &.form{
            padding: 20px;
            margin-bottom: 20px;
            @include mappy-bp(mediumish){
                padding: 45px;
                margin-bottom: 80px;
            }

            header.options-selection{
            }

            form{
                padding: 41px 30px 35px;
                margin: 10px auto 25px;
                border: 1px solid #cccccc;
                background-color: transparent;
                input{
                    margin: 0;
                    @include placeholder{
                        color: #4a4a4a;
                        font-family: $font-family-1;
                        font-size: 12px;
                        font-weight: 300;
                    }
                }
                .form-field{
                    color: #4a4a4a;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 17px;
                    text-align: left;
                    padding-bottom: 11px;
                    @include mappy-bp(medium mediumish){
                        width: 48%;
                        margin-right: 2%;
                    }
                    .town-field{
                        max-width: 66.5%;
                        margin-right: 3%;
                        @include mappy-bp(medium){
                            max-width: 58.5%;
                            margin-right: 8px;
                        }
                        @include mappy-bp(mediumish){
                            width: 134px;
                        }
                    }
                    .postcode-field{
                        width: 88px;
                    }
                    &.terms-and-note{
                        display: flex;
                        float: left;
                        position: relative;
                        label{
                            display: block;
                            margin-left: 10px;
                            line-height: 17px;
                            transition: all .5s ease-in-out;
                            text-transform: none !important;
                        }

                        input{
                            left: 0;
                            // margin-top: 2px;
                            margin-top: -2px;
                            float: left;
                            width: 25px;
                        }
                        &:hover{
                            .terms-link label{
                                color: $color-brand-1;
                                cursor: pointer;
                            }
                        }
                        & label:last-child{ widht:100%; }

                        @include mappy-bp(medium mediumish){
                            width: 49%;
                            input{
                                right: 23%;
                            }
                        }
                    }
                }
                .button-field{
                    float: left;
                    text-align: left;
                    margin-bottom: 0;
                    button{
                        float: none;
                        margin: 10px 0 7px;
                        font-size: 16px;
                        font-weight: 500;
                        width: 112px;
                        height: 40px;
                    }
                    a{
                        color: #4a4a4a;
                        font-size: 12px;
                        font-weight: 500;
                        i{
                            margin-right: 5px;
                        }
                    }
                }
                p{
                    color: #4a4a4a;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 19px;
                    text-align: left;
                }
            }
        }
    }
}
