/*********************************************
:: Build Price Module
********************************************/
.build-price-module {
    width: 100%;
    padding-top: 20px;

    text-align: center;
    background-color: #fff;

    z-index: 100;
    transition: all 0.5s linear;

    &.active {
        transition: all 0.5s linear;
    }

    //@include m(max) {
    //    div:not(.price) {
    //        display: none;
    //    }
    //}

    .options-total {
        margin-bottom: 25px;
        div {
            vertical-align: top;
            display: inline-block;
            @include mappy-bp(tiny mediumish){
                width: 26%;
                display: inline-block;
                margin-right: 2%;
                margin-bottom: 25px;
                min-height: 65px;
            }
            @include mappy-bp(mediumish){
                margin-right: 1.9%;
            }
            &:last-of-type{
                margin-bottom: 0;
            }

            p {
                display: block;
                text-align: center;

                color: #4a4a4a;
                font-size: 12px;
                font-weight: 300;

                &:first-child {
                    margin-bottom: 0;
                    font-size: 16px;
                    color: #4a4a4a;
                    font-weight: 500;
                    @include mappy-bp(medium){
                        font-size: 18px;
                    }
                }
                &.total{
                    font-size: 27px;
                    color: $color-brand-4;
                    @include mappy-bp(mediumish){
                        font-size: 34px;
                        margin-top: -3px;
                        margin-bottom: 3px;
                    }
                }
            }

            &.price {
                margin-right: 32px;
                text-align: right;

                p:first-child {
                    margin-bottom: 9px;
                }

                p:last-child {
                    color: #7bb8d4;
                    font-size: 34px;
                    font-weight: 400;
                    line-height: 34px;
                }
            }
            .gb-title{
                display: none;
            }
        }

        .separator, .separator1 {
            position: relative;
            margin: -10.3px 2.5% 0 0;
            height: 72px;
            width: 1px;
            background-color: #cccccc;
            display: inline-block;
            //display: none;
            @include mappy-bp(mediumish){
                margin: -19.3px 1.9% 0 0;
                height: 86px;
            }
            &:after{
                position: absolute;
                height: 18px;
                width: 21px;
                top: 36%;
                left: -8px;
                right: 0;
                background-image: url(/images/icons/separator.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-color: #efefef;
                content: '';
            }
        }

        .separator1 {
            &:after {
                background-image: url(/images/icons/separator1.png);
            }
        }

        button {
            display: inline-block;
            width: 126px;
            height: 48px;
            border-radius: 5px;
            border: 1px solid #7bb8d4;

            color: #7bb8d4;
            font-size: 20px;
            font-weight: 400;
            line-height: 46px;

            text-align: center;
            vertical-align: bottom;
        }
    }
}
/********************************************/