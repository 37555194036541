/*********************************************
:: Testimonials
********************************************/
.page-testimonials {

    .detail-container {
        display: inline-block;
        width: 100%;
        overflow: hidden;

        article {
            display: none;
            float: left;
            width: 100%;
            padding-bottom: 40px;
            clear: both;

            .author {
                margin: 20px 0 0 0;
                padding: 20px 0 30px 0;
                border-top: 3px solid $color-brand-1;

                @include mappy-bp(mediumish){
                    float: left;
                    margin: 0 80px 0 0;
                    width: 160px;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 20px;
                    color: $color-brand-1;
                    line-height: 21px;
                }

                span {
                    display: block;
                    font-size: 14px;
                    color: #aaa;
                }

                img {
                    margin-top: 30px;
                    max-width: 220px;
                }
            }

            .quote {
                position: relative;
                padding: 0;

                @include mappy-bp(mediumish){
                    float: right;
                    margin-top: -8px;
                    padding-left: 20px;
                    width: calc(100% - 240px);
                }

                &:before{
                    @include mappy-bp(mediumish){
                        content: "\0022";
                        position: absolute;
                        top: -4px;
                        left: 0;

                        font-size: $font-family-1;
                        font-size: 28px;
                        font-style: italic;
                        color: #333;
                        
                        letter-spacing: 0.5px;
                    }
                }
            }
        }

        article.special {
            display: none;

            @include mappy-bp(mediumish){
                display: inline-block;
                width: 100%;
                padding: 10px 12.5% 50px 12.5%;
            }

            .author {
                display: none;
            }

            .quote {
                float: left;
                position: relative;
                padding: 0 50px 0 0; 
                width: 100%;

                border-right: 3px solid $color-brand-1;

                &:before{
                    content: "";
                }

                p {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 36px;
                    color: $color-brand-1;
                    text-align: right;
                    margin-bottom: 0px;
                }

                a {
                    display: none;
                }
            }
        }

        article.show {
            display: inline-block;
            animation: fade-in .5s;
        }

        article:last-child {
            padding-bottom: 100px;
        }

        .button-more {
            display: none;
        }

    }//DETAIL-CONTAINER

}