/*********************************************
:: Coverage Page
********************************************/
.page-coverage {

    .title-module{
        @include mappy-bp(mediumish){
            margin-bottom: 84px;
        }
    }
    .content-container {
        display: inline-block;
        width: 100%;
        @include mappy-bp(mediumish){
            margin-bottom: 40px;
        }

        section{
            article{
                @include mappy-bp(mediumish){
                    //max-width: 43.9%;
                }
                &.wysiwyg{
                    p{
                        font-size: 16px;
                        line-height: 33px;
                    }
                    figure{
                        @include mappy-bp(mediumish){
                            margin: 56px auto 40px;
                        }
                    }
                }
            }
        }

        header.title{
            margin-bottom: 30px;
            @include mappy-bp(mediumish){
                margin-bottom: 34px;
            }
        }
    }//CONTENT-CONTAINER
}