/*********************************************
:: Boxes Module                    
********************************************/
.boxes-module{
	width: 100%;
	display: inline-block;
    position: relative;
    font-size: 0;
    overflow: hidden;
	@include mappy-bp(mediumish){
        margin-bottom: 75px;

        &:after {
            height: 220px;
        }
	}

    a{
        font-size: 0;

        &.has-content{
          &:hover{
            opacity: 0.9;
          }
        }
    }

    article{
        position: relative;
        padding: 20px 0 18px;
        vertical-align: top;
        @include mappy-bp(mediumish){
            padding: 26px 0 19px;
            display: inline-block;            
        }
        &.big-box{
            @include mappy-bp(mediumish){
                width: 510px;
                height: 423px;
                .content{
                    width: 100%;
                    padding: 32px 23px 32px 29px;
                }
            }
        }
        .content{
            padding: 33px 41px 16px 27px;
            position: absolute;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);

            @include mappy-bp(mediumish){
                padding: 9px 23px 12px 29px;
            }
            p{
                margin-bottom: 0;
            }

            header, p{    
                font-size: 13px;
                font-weight: 700;
                color: white;
    
                line-height: 16px;
                z-index: 9;
                text-transform: initial;
                @include mappy-bp(mediumish){
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            p{
                font-weight: 100;
            }
        }
    }
    .small-boxes{
        @include mappy-bp(mediumish){
            display: inline-block;
            max-width: 55%;
            height: 423px;
            overflow: hidden;
        }
        article{
            // width: 311px;
            width: 100%;
            height: 211.5px;
            background-size: cover;
            .content{
                width: 100%;
                @include mappy-bp(mediumish){
                    padding: 9px 23px 13px;
                }
            }
        }
    }
    .boxes-slide-show{
        .content{
            width: 100%;
            padding: 13px 41px 15px 27px;
        }
        .slick-prev, .slick-next{
            height: 26px;
            z-index: 9;
            &::before{
                height: 26px;
                width: 22px;
                content: '';
                background-image: url(/images/icons/arrow-right.svg);
                background-size: contain;
                position: absolute;
                background-repeat: no-repeat;
                top: 0;
            }
        }

        .slick-prev{
            left: 0;
            &::before{
                left: -1px;
                transform: rotate(180deg);
            }
        }
        .slick-next{
            right: 0;
            &::before{
                right: -2px;
            }
        }
    }

    .boxes-slide-show{
        margin-bottom: 20px;
    }
}
/********************************************/