/*********************************************
:: Pagination Module                    
********************************************/
.pagination-module{
	width: 100%;
	margin: 0 auto;
	padding: 20px 0;
	display: inline-block;
	text-align: center;
	background: whitesmoke;
	@include mappy-bp(mediumish){
		background: white;
	}

	ul {
		width: auto;
		display: inline-block;
		height: 34px;
		padding: 0;
	    margin: 6px;
		text-align: center;
		background: $color-brand-2;
		border-radius: 17px;
		@include mappy-bp(mediumish){
			margin: 0px;
		}

		li {
			width: 28px;
			height: 28px;
			display: inline-block;
			margin: 3px;

			a {
				line-height: 28px;
				text-align: center;
				color: $color-brand-1;
				font-family: $font-family-2;
				font-size: 1.25em;
			}

			span {
				color: $color-brand-1;
			    vertical-align: sub;
				font-size: 26px;
				// padding: 0px 10px 0 10px;
			}

			&.active {
				background: $color-brand-1;
				border-radius: 50%;

				a {
					color: white;
				}
			}
		}
	}
	/********************************************/

}
/********************************************/

