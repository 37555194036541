/*********************************************
  :: Site Footer
********************************************/
.site-footer {
    width: 100%;
    min-height: $footer-height-mobile;

    position: relative;
    padding-top: 24px;

    background: white;
    font-size: 0;

    @include mappy-bp(mediumish){
        padding-top: 50px;
        min-height:322px;
    }

    .footer-content{
        .left{
            text-align: center;
            padding-top: 26px;
            @include mappy-bp(max-width mediumish){
                border-top: 1px solid #979797;
            }
            @include mappy-bp(mediumish){
                padding-top: inherit;
                text-align: left;
                width: 50%;
                display: inline-block;
            }
            article{
                //display: none;
                vertical-align: top;
                @include mappy-bp(mediumish){
                    display: inline-block;
                }
                &.see-also{
                    @include mappy-bp(mediumish){
                        margin-right: 22%;
                    }
                }
                header{
                    color: $color-brand-1;
                    font-size: 12.5px;
                    font-weight: 400;
                    margin-bottom: 15px;
                }
                ul{
                    li{
                        @include mappy-bp(max-width smallish){
                            text-align: center;
                            margin-bottom: 10px;
                        }
                        &:first-of-type{
                            margin-left: 0;
                        }
                        margin-bottom: 0;
                        font-size: 13px;
                        line-height: 19px;
                        a{
                            line-height: 19px;
                            color: #4a4a4a;
                            font-weight: $font-lighter;
                        }
                        &.btn{
                            margin-top: 12px;
                            a{
                                line-height: 15px;
                                background-color: $color-brand-1;
                                font-weight: 300;
                                height: 31px;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
        .right{
            display: inline-block;
            vertical-align: top;
            width: 100%;
            text-align: center;
            @include mappy-bp(mediumish){
                width: 50%;
                text-align: right;
            }
            ul{
                li{
                    display: inline-block;
                    margin-left: 7px;
                    @include mappy-bp(mediumish){
                        margin-left: 0;
                    }
                    a{
                        display: inline-block;
                        margin-left: 10px;
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
            }
            .logos{
                @include mappy-bp(mediumish){
                    margin-top: 26px;
                }
                li{
                    margin-right: 10px;
                    max-width: 39px;
                    width: 100%;
                    height: 38px;

                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    &:last-of-type{
                        margin-right: 0;
                        background-size: 90%;
                    }
                    &.larger{
                        max-width: 81px;
                        @include mappy-bp(mediumish){
                            max-width: 187px;
                            margin-right: 14px;
                        }
                    }
                    @include mappy-bp(mediumish){
                        margin-right: 12px;
                        max-width: 95px;
                        width: 100%;
                        height: 104px;
                    }
                }
            }
        }
    }

    .footer-legal {
        position: absolute;
        bottom: 31px;
        width: 100%;
        @include mappy-bp(mediumish){
            max-width: 1149px;
            margin: auto;
            right: 0;
            left: 0;
            bottom: 25px;
            border-top: 1px solid #cccccc;
            padding-top: 20px;
        }
        
        p, a {
            display: inline-block;
            width: 100%;

            font-size: 11px;
            line-height: 11px;
            text-align: center;
            //font-weight: $font-normal;
            color: #9b9b9b;

            @include mappy-bp(mediumish){
                width: auto;
            }
        }
 
        p.copyright {
            margin-bottom: 5px;
            font-weight: 100;

            @include mappy-bp(mediumish){
                float:left;
                margin-bottom: 0;
            }

            a {
                font-size: 11px;
                line-height: 11px;
                color: $color-brand-4;
            }
        }

        a.attribution {
            font-weight: 100;
            @include mappy-bp(mediumish){
                float:right;
            }
        }
    }
}
